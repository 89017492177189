<template>
	<div>
		<Footer></Footer>
		<div class="content">
			<!-- 大赛回顾 -->
			<div class="retrospect_box1">
				<span class="Retrospect_tite">新闻动态</span>
				<div class="Retrospect">

					<div class="Retrospect_cont">
						<img class="Retrospect_img" src="@/assets/images/nBG2.jpg" alt="">
						<span class="Retrospect_jan">2022-8-10</span>
						<div class="Retrospect_title">
							<span>第九届“大唐杯”（北区）全国总决赛成功举办</span>
						</div>
						<span
							class="Retrospect_content">与会嘉宾在颁奖典礼致词时表示新一代信息技术已成为支撑所有工业数字化转型的基础底盘，5G作为信息技术的核心技术，不仅影响着全球产业布局，也决定着国家民族的前途命运。</span>
						<span class="Retrospect_foot" @click="viewDetail('nineB')">
							阅读详情>>>
						</span>
					</div>

					<div class="Retrospect_cont">
						<img class="Retrospect_img" src="./Journalism_img/pexels-photo.png" alt="">
						<span class="Retrospect_jan"> 2022-07-20</span>
						<div class="Retrospect_title">
							<span>第九届“大唐杯”比赛精彩回顾省赛&国赛&全国双师型教师赛</span>
						</div>
						<span
							class="Retrospect_content">大赛积极顺应数字化转型的时代浪潮，助力高校实现人才培养和教学质量双提升，实现“企业用人”与“高校育人”双循环。新产教联合培养，创新育人模式与创新工程实践在此起航。</span>
						<span class="Retrospect_foot" @click="viewDetail('nineN')">
							阅读详情>>>
						</span>
					</div>



					<div class="Retrospect_cont">
						<img class="Retrospect_img" src="@/assets/images/NNW.jpg" alt="">
						<span class="Retrospect_jan"> 2022-07-17</span>
						<div class="Retrospect_title">
							<span>第九届“大唐杯”比赛精彩回顾省赛&国赛&全国双师型教师赛</span>
						</div>
						<span
							class="Retrospect_content">大赛积极顺应数字化转型的时代浪潮，以“加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同全国高校在5G通信技术实训平台、双师培养、高质量课程体系建设、5G+垂直产业创新应用等方面的深度融合，助力高校实现人才培养和教学质量双提升，实现“企业用人”与“高校育人”双循环。</span>
						<span class="Retrospect_foot" @click="viewDetail('play')">
							阅读详情>>>
						</span>
					</div>




				</div>
			</div>



		 <!-- 大赛回顾 -->
			<div class="retrospect_box">
				<!--<span class="Retrospect_tite">大赛回顾</span>-->
				<div class="Retrospect">


					<div class="Retrospect_cont">
						<img class="Retrospect_img" src="@/assets/images/nW1.jpg" alt="">
						<span class="Retrospect_jan">2022-07-15</span>
						<div class="Retrospect_title">
							<span>第九届“大唐杯”比赛精彩回顾省赛&国赛&教师赛与闭幕式</span>
						</div>
						<span
							class="Retrospect_content">2022年4月10日~16日，经过大唐杯组委会数月的精心筹备与全国各赛区激烈的角逐，第九届“大唐杯”全国大学生移动通信5G技术大赛省赛如期圆满落幕。自荣登高等教育学会“全国大学生竞赛排行榜”榜单以来，大赛持续保持高质量，高水平。</span>
						<span class="Retrospect_foot" @click="viewDetail('nineW')">
							阅读详情>>>
						</span>
					</div>


					<div class="Retrospect_cont">
						<img class="Retrospect_img" src="./Journalism_img/pexels-photo-302899.png" alt="">
						<span class="Retrospect_jan">2021-07-15</span>
						<div class="Retrospect_title">
							<span>第八届“大唐杯”比赛精彩回顾省赛&国赛&教师赛与闭幕式</span>
						</div>
						<span
							class="Retrospect_content">本届大赛在践行教育部、工信部倡导的“政、产、学、研、用”思路，推动专业教学与产业工程深度融合，落实落地新工科新举措，提升教学水平和人才培养质量等方面的影响力持续提升。</span>
						<span class="Retrospect_foot" @click="viewDetail('eight')">
							阅读详情>>>
						</span>
					</div>

					<div class="Retrospect_cont">
						<img class="Retrospect_img" src="./Journalism_img/pexels-photo-1752806.png" alt="">
						<span class="Retrospect_jan">2020-10-30</span>
						<div class="Retrospect_title">
							<span>第七届“大唐杯”比赛精彩回顾闭幕式暨颁奖典礼</span>
						</div>
						<span
							class="Retrospect_content">北方工业大学副校长王建稳、中信科移动副总经理蔡鑫、工信部人才中心副主任李宁、北京市教委高教处处长刘霄、中国通信协会副秘书长李北林、北京邮电大学教务处长王卫东、中信科移动5G产品线副总裁李文、中信科移动客服中心总经理蒋珀等领导出席颁奖典礼。</span>
					 <span class="Retrospect_foot" @click="viewDetail('seven')">
							阅读详情>>>
						</span>
					</div>


				</div>
			</div>
		</div>
		<Bottom></Bottom>
	</div>
</template>

<script>
	import Footer from '@/components/Footer/Footer.vue'
	import Bottom from '@/components/Bottom/Bottom.vue'
	import {
		article
	} from "@/api/apis.js"
	import $ from "jquery";
	export default {
		name: 'Home',
		components: {
			Footer,
			Bottom
		},
		data() {
			return {

			}
		},
		created() {},
		methods: {

			viewDetail(type) {
				this.$router.push({
					path: '/ViewDetail',
					query: {
						vType: type
					}
				})
			}
		},
		mounted() {

		},

	}
</script>

<style scoped lang="scss">
	@import './Journalism.scss';
</style>
